import React, {ChangeEvent, KeyboardEvent, useContext, useEffect, useState} from "react";
import {SearchIcon} from "@heroicons/react/outline";
import Pagination from "./components/Pagination";
import {AuthContext} from "../../providers/AuthProvider";
import Button, {ButtonStyle} from "../../components/Button/Button";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Heading from "../../components/Typography/Heading";
import Text from "../../components/Typography/Text";
import {TableHeader} from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import StockMutation from "./components/StockMutation";
import WarningModal from "../../components/Modal/WarningModal";
import Select from "react-select";
import StockUploadModal from "./components/StockUploadModal";
import StockDownloadModel from "./components/StockDownloadModal";
import ProductTableRow from "./components/ProductTableRow";
import {toast} from "react-toastify";
import {StoreProduct} from "../../plugins/middleware-api-client";
import BulkUploadModal from "./components/BulkUploadModal";

interface SelectOption {
  value: string;
  label: string;
}

const ProductList: React.FC = () => {
  const authProvider = useContext(AuthContext);
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const [productToDelete, setProductToDelete] = useState<string>("");
  const [addingStock, setAddingStock] = useState<boolean>(false);
  const [uploadStock, setUploadStock] = useState<boolean>(false);
  const [uploadBulk, setUploadBulk] = useState<boolean>(false);
  const [downloadStock, setDownloadStock] = useState<boolean>(false);
  const [productRefreshInterval, setProductRefreshInterval] =
    useState<NodeJS.Timer | null>(null);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const {search} = useLocation();

  const p = parseInt(searchParams.get("p") || "1");
  const filter = searchParams.get("filter") || "";
  const category = searchParams.get("category") || "";
  const supplier = searchParams.get("supplier") || "";
  const farmerParam = searchParams.get("farmer") || "";
  const scrollY = searchParams.get("scrollY") || "";

  const selectedFarmers: SelectOption[] = authProvider.farmers
    .filter(farmer => farmerParam.includes(farmer.id))
    .map(farmer => {
      return {
        value: farmer.id,
        label: farmer.name,
      }
    }) ?? []

  const farmerOptions = authProvider.farmers.map(farmer => {
    return {
      value: farmer.id,
      label: farmer.name,
    };
  });

  useEffect(() => {
    if (scrollY) {
      window.scrollTo(0, parseInt(scrollY));
      searchParams.delete("scrollY");
      setSearchParams(searchParams);
    }
  }, [scrollY]);

  const editProduct = (productId: string) => {
    const scrollY = search.startsWith("?")
      ? `&scrollY=${window.scrollY}`
      : `?scrollY=${window.scrollY}`;
    navigate(`/products/${productId}${search}${scrollY}`);
  };


  useEffect(() => {

    if (productRefreshInterval) {
      clearInterval(productRefreshInterval);
    }

    let interval: NodeJS.Timer;
    if (!addingStock) {
      interval = setInterval(() => {
        authProvider.fetchProducts(p, filter, supplier, category, farmerParam, authProvider.activeStoreId);
      }, 5000);
      setProductRefreshInterval(interval);
    }

    // authProvider.fetchProducts(p, filter, supplier, category, farmerParam, authProvider.activeStoreId);
    authProvider.fetchFarmers()

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [filter, p, supplier, category, addingStock, farmerParam, authProvider.activeStoreId]);

  const filterResults = (e: ChangeEvent<HTMLInputElement>) => {
    searchParams.set("filter", e.target.value.toLowerCase());
    searchParams.delete("p");
    setSearchParams(searchParams);
  };

  const closeStockPopUp = () => {
    setAddingStock(false);
    setTimeout(function () {
      authProvider.fetchProducts(p, filter, supplier, category, farmerParam, authProvider.activeStoreId);
    }, 1000);
  };

  const closeUploadModal = () => {
    setUploadStock(false);
  };

  const closeDownloadModal = () => {
    setDownloadStock(false);
  }

  const closeBulkUploadModal = () => {
    setUploadBulk(false);
  };

  const categories: string[] = [
    "Groenten",
    "Fruit",
    "Kaas",
    "Zuivel",
    "Vlees",
    "Bakkerij",
    "Dranken",
    "Diner",
    "Overig",
  ];

  useEffect(() => {
    authProvider.fetchProducts(p, filter, supplier, category, farmerParam, authProvider.activeStoreId);
  }, [authProvider.activeStore])

  useEffect(() => {
    if (productToDelete.length > 0) {
      setShowAlertModal(true);
    }
  }, [productToDelete]);

  useEffect(() => {
    if (!showAlertModal) {
      setProductToDelete("");
    }
  }, [showAlertModal]);

  const deleteProduct = async () => {
    await authProvider.deleteProduct(productToDelete);
    setShowAlertModal(false);
  };

  return (
    <Panel>
      <div className="sm:flex sm:items-center sticky top-0 bg-white pb-4 z-10">
        <div className="sm:flex-grow">
          <Heading>Producten</Heading>
          <Text>Alle producten van "{authProvider.activeStore?.name}"</Text>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 space-x-2">
          <Button
            onClick={() => {
              setAddingStock(true);
            }}
            buttonStyle={ButtonStyle.NEW}
          >
            Voorraad toevoegen
          </Button>

          <Button
            onClick={() => {
              navigate("/products/create");
            }}
            buttonStyle={ButtonStyle.NEW}
          >
            Product toevoegen
          </Button>

          <Button
            buttonStyle={ButtonStyle.NEW}
            onClick={
              () => setDownloadStock(true)
            }
          >
            Bestellijst downloaden
          </Button>

          <Button
            buttonStyle={ButtonStyle.NEW}
            onClick={() => authProvider.exportProductStock(
              filter,
              supplier,
              category,
              farmerParam
            )}
          >
            Productenlijst downloaden
          </Button>

          <Button
            buttonStyle={ButtonStyle.NEW}
            onClick={() => setUploadStock(true)}
          >
            Upload Voorraad
          </Button>

          <Button
            buttonStyle={ButtonStyle.NEW}
            onClick={() => setUploadBulk(true)}
          >
            Upload Bulk
          </Button>

        </div>
      </div>

      <StockMutation open={addingStock} closeStockPopUp={closeStockPopUp}/>
      <StockUploadModal open={uploadStock} closeModal={closeUploadModal}/>
      <StockDownloadModel open={downloadStock} closeModal={closeDownloadModal} filter={filter} category={category}
                          supplier={supplier} farmerParam={farmerParam}/>
      <BulkUploadModal open={uploadBulk} closeModal={closeBulkUploadModal}/>

      <div className="mt-8 flex flex-col overflow-hidden">
        <div className="py-2">
          <div className="flex flex-row flex-wrap justify-between">
            <div id="search" className="mb-4 flex-grow">
              <div className="w-full sm:w-1/2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-secondary mb-2"
                >
                  Zoek een product
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="productFilter"
                    id="productFilter"
                    className="text-secondary focus:ring-secondary w-full focus:border-secondary block pl-10 sm:text-sm border-gray-300 rounded-md"
                    defaultValue={filter}
                    onChange={filterResults}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        authProvider.fetchProducts(p, filter, supplier, category, farmerParam, authProvider.activeStoreId)
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div id="filters" className="flex flex-wrap space-x-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-secondary mb-2"
                >
                  Boer
                </label>
                {authProvider.farmers &&
                    <div className="mt-1 relative rounded-md shadow-sm w-full">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                        </div>

                        <Select
                            defaultValue={selectedFarmers}
                            isMulti
                            name="colors"
                            options={farmerOptions}
                            className="basic-multi-select focus:ring-secondary focus:border-secondary"
                            classNamePrefix="select"
                            onChange={(selectOption: any) => {
                              searchParams.set("farmer", selectOption.map((option: any) => option.value).join(','));
                              searchParams.delete("p");
                              setSearchParams(searchParams)
                            }}
                        />
                    </div>
                }
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-secondary mb-2"
                >
                  Categorie
                </label>
                <div className="mt-1 relative rounded-md shadow-sm w-full">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <select
                    id="category"
                    name="category"
                    className="pl-10 max-w-lg block focus:ring-secondary focus:border-secondary w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    onChange={(e) => {
                      searchParams.set("category", e.target.value);
                      searchParams.delete("p");
                      setSearchParams(searchParams);
                    }}
                    defaultValue={category}
                  >
                    <option key="category_all" value="">
                      -
                    </option>
                    {categories.map((category, i) => (
                      <option key={`category_${i}`} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-scroll">
            <table className="w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
              <tr>
                <TableHeader>Product</TableHeader>
                <TableHeader>Categorie</TableHeader>
                <TableHeader>Voorraad</TableHeader>
                <TableHeader>Inkoopprijs</TableHeader>
                <TableHeader>Verkoopprijs</TableHeader>
                <TableHeader>Marge</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>
                  <span className="sr-only">Verwijderen</span>
                </TableHeader>
                <TableHeader>
                  <span className="sr-only">Bewerken</span>
                </TableHeader>
              </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
              {authProvider.products.map((product) => {

                const storeProduct = authProvider.storeProducts.find((storeProduct) => storeProduct.productId === product.id && storeProduct.storeId === authProvider.activeStoreId)

                return (
                  <ProductTableRow
                    key={product.id}
                    product={product}
                    storeProduct={storeProduct || {stockLevel: 0} as StoreProduct}
                    farmerName={
                      authProvider.farmers?.filter(
                        (f) => f.id === product.farmer
                      )[0]?.name
                    }
                    className="hover:backdrop-brightness-95"
                    onDoubleClick={() => editProduct(product.id)}
                    deleteCallback={() => setProductToDelete(product.id)}
                    confirmCallback={(p) =>
                      authProvider.updateProduct(p.id, p).then(() => toast.success("Product succesfully updated!", {autoClose: 1500}))

                    }
                  ></ProductTableRow>
                )
              })}
              </tbody>
            </table>
            {p && (
              <Pagination
                page={p}
                hasNextPage={authProvider.products.length >= 100}
                onPageChange={(page: number) => {
                  searchParams.set("p", page.toString());
                  setSearchParams(searchParams);
                  window.scrollTo(0, 0);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <WarningModal
        isOpen={showAlertModal}
        setIsOpen={setShowAlertModal}
        confirmAction={deleteProduct}
        cancelAction={setShowAlertModal}
      />
    </Panel>
  );
};

export default ProductList;
