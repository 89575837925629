import React, {FormEvent, Fragment, useContext, useEffect, useState,} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {AuthContext} from "../../../providers/AuthProvider";
import Button, {ButtonStyle} from "../../../components/Button/Button";
import Text from "../../../components/Typography/Text";
import {BulkMutationV2} from "../../../plugins/middleware-api-client";
import {useCSVReader} from "react-papaparse";

interface BulkUploadProps {
  open: boolean;
  closeModal: () => void;
}

const BulkUploadModal: React.FC<BulkUploadProps> = (
  props: BulkUploadProps
) => {
  const authContext = useContext(AuthContext);

  const [storeId, setStoreId] = useState<string>("");

  const [buttonLook, setButtonLook] = useState<string>(ButtonStyle.NEW);
  const [buttonText, setButtonText] = useState<string | undefined>(undefined);
  const [csvFile, setCsvFile] = useState<any[]>([]);
  const { CSVReader } = useCSVReader();

  const handleSubmit = async (mutations: BulkMutationV2[]) => {
    console.log(mutations);
    const res = await authContext.bulkMutationsV2(mutations);
    if (res) {
      props.closeModal();
    }
  };

  const handleCsvFile = (csvFile: any[], file: string) => {
    setCsvFile(csvFile);
    try {
      updateButton(ButtonStyle.SAVE, file);
    } catch (e) {
      updateButton(ButtonStyle.ERROR, "Fout gevonden in een EAN code");
    }
  };

  const convertCsvFileToMutations = () => {
    return csvFile.map((row) => {
      return {
        eanCode: parseInt(row["EAN code"]),
        margin: row["Marge"] ? parseInt(row["Marge"]) : undefined,
        priceIncl: row["Prijs incl"] ? parseInt(row["Prijs incl"]) : undefined,
        priceExcl: row["Prijs excl"] ? parseInt(row["Prijs excl"]) : undefined,
        costPrice: row["Inkoopprijs"] ? parseInt(row["Inkoopprijs"]) : undefined,
        storeId: storeId,
      };
    });
  };

  const updateButton = (style: ButtonStyle, buttonText: string) => {
    setButtonLook(style);
    setButtonText(buttonText);
  };

  useEffect(() => {
    if (authContext.stores[0]) {
      setStoreId(authContext.stores[0].id);
    }
  }, [authContext.stores]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => props.closeModal()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 md:w-1/2">
              <form
                onSubmit={async (e: FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  let mutations = convertCsvFileToMutations();
                  handleSubmit(mutations);
                }}
                className="space-y-8"
              >
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-secondary"
                    >
                      Prijs Uploaden
                    </Dialog.Title>
                    <div className="mt-2">
                      <Text classList="text-sm">
                        {/* Text to display under the header in the modal can go here */}
                      </Text>
                    </div>
                  </div>
                  <label
                    htmlFor="uploadButton"
                    className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2 after:content-['*'] after:text-red-500"
                  >
                    Bulk Bestand
                  </label>
                  <CSVReader
                    onUploadAccepted={(results: any, file: File) => {
                      if (
                        !results.data[0].hasOwnProperty("EAN code") ||
                        !results.data[0].hasOwnProperty("Marge") ||
                        !results.data[0].hasOwnProperty("Prijs incl") ||
                        !results.data[0].hasOwnProperty("Prijs excl") ||
                        !results.data[0].hasOwnProperty("Inkoopprijs")
                      ) {
                        setButtonLook(ButtonStyle.ERROR);
                        return;
                      }
                      updateButton(ButtonStyle.CANCEL, "");
                      handleCsvFile(results.data, file.name);
                    }}
                    config={{
                      header: true,
                    }}
                  >
                    {({ getRootProps, acceptedFile }: any) => (
                      <>
                        <Button
                          type="button"
                          buttonStyle={buttonLook}
                          {...getRootProps()}
                          id="uploadButton"
                        >
                          {(buttonText && buttonText) ||
                            (acceptedFile &&
                              acceptedFile.name.substring(0, 10)) ||
                            "Upload"}
                        </Button>
                      </>
                    )}
                  </CSVReader>
                  <div
                    style={{ maxHeight: "60vh" }}
                    className="overflow-y-scroll"
                  >
                    <label
                      htmlFor="store"
                      className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2 after:content-['*'] after:text-red-500"
                    >
                      Winkel
                    </label>
                    <select
                      id="store"
                      name="store"
                      className="max-w-lg block focus:ring-secondary focus:border-secondary w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      onChange={(e) => {
                        setStoreId(e.target.value);
                      }}
                      value={storeId}
                    >
                      {authContext.stores.map((store) => (
                        <option key={store.id} value={store.id}>
                          {store.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex justify-between mt-5 sm:mt-6 sticky bottom-0 bg-white">
                  <Button
                    type="button"
                    buttonStyle={ButtonStyle.CANCEL}
                    onClick={() => {
                      setStoreId(authContext.stores[0].id);
                      props.closeModal();
                    }}
                  >
                    Annuleren
                  </Button>
                  <Button
                    disabled={csvFile.length === 0}
                    buttonStyle={
                      csvFile.length === 0
                        ? ButtonStyle.ERROR
                        : ButtonStyle.SAVE
                    }
                  >
                    Toevoegen
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BulkUploadModal;
