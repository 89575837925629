import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {StoreConfigurationCreateRequest, StoreCreateRequest} from "../../plugins/middleware-api-client";
import StoreForm from "./components/StoreForm";
import {AuthContext} from "../../providers/AuthProvider";
import Panel from "../../components/Panel/Panel";

const EditStore:React.FC = () => {

  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  const handleSubmit = (store:StoreCreateRequest, storeConfiguration: StoreConfigurationCreateRequest) => {
    if(authContext.activeStore?.id){
      authContext.updateStore(authContext.activeStore.id, store)
        .then(res => {
          if (res) {
            authContext.updateStoreConfiguration(authContext.activeStore!.id, storeConfiguration).then(resConfig => {
              if (resConfig) {
                navigate('/')
              }
            })
          }
        }).catch(err => {
          console.error(err)
      })
    }
  }

  return (
    <Panel>
      <StoreForm handleSubmit={handleSubmit} handleCancel={() => navigate('/')} storeToUpdate={authContext.activeStore} storeConfigurationToUpdate={authContext.activeStoreConfiguration}/>
    </Panel>
  )
}

export default EditStore;
