import {Product} from "../plugins/middleware-api-client";

const deeplinkScheme = "oogst-van-hier://"

export function calculatePriceIncl(product: Product) {
  return Math.round((product.costPrice / (1 - product.margin / 100) * (1 + product.vat / 100)));
}

export function calculateCostPrice(product: Product) {
  return Math.round((product.priceIncl / (1 + product.vat / 100) * (1 - product.margin / 100)));
}

export function calculateProfitMargin(product: Product) {
  const priceExcl = Math.round(product.priceIncl / (1 + product.vat / 100));
  const costPriceDifference = priceExcl - product.costPrice;
  return Math.round(costPriceDifference / priceExcl * 100);
}

export function calculateDiscountPrice(product: Product, includeVat: boolean = false) {
  let priceExcl = Math.round(product.costPrice / (1 - product.margin / 100));
  let discountPrice = 0;
  if (product.discountType === "percentage") {
    discountPrice = Math.round(priceExcl - (priceExcl * ((product.discountValue ?? 0) / 100)));
  } else if (product.discountType === "price") {
    discountPrice = Math.round(priceExcl - (product.discountValue ?? 0));
  }

  if (includeVat) {
    return Math.round(discountPrice * (1 + product.vat / 100));
  }
  return discountPrice;
}

export function getQrCodeValue(product: Product) {
  return deeplinkScheme + product.id
}