import {OpeningHours} from "../plugins/middleware-api-client";

export function sortOpeningHours(openingHours: OpeningHours): OpeningHours {
  const daysOfWeekOrder: (keyof OpeningHours)[] = [
    "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"
  ];

  const sortedOpeningHours: OpeningHours = {};

  daysOfWeekOrder.forEach((day) => {
    if (openingHours[day]) {
      sortedOpeningHours[day] = openingHours[day];
    }
  });

  return sortedOpeningHours;
}

export function getOpeningHourDay(day: string): string {
  switch (day) {
    case "monday":
      return "maandag";
    case "tuesday":
      return "dinsdag";
    case "wednesday":
      return "woensdag";
    case "thursday":
      return "donderdag";
    case "friday":
      return "vrijdag";
    case "saturday":
      return "zaterdag";
    case "sunday":
      return "zondag";
    default:
      return ""
  }
}