import React, {useContext} from "react";
import {StoreConfigurationCreateRequest, StoreCreateRequest} from "../../plugins/middleware-api-client";
import {AuthContext} from "../../providers/AuthProvider";
import StoreForm from "./components/StoreForm";
import {useNavigate} from "react-router-dom";
import Panel from "../../components/Panel/Panel";

const CreateStore: React.FC = () => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const handleSubmit = (store: StoreCreateRequest, storeConfiguration: StoreConfigurationCreateRequest) => {
    authContext.createStore(store).then(res => {
      if (res) {
        authContext.createStoreConfiguration(res, storeConfiguration).then(resConfig => {
          if (resConfig) {
            navigate('/')
          }
        })
      }
    })
    console.log(store)
  }

  return (
    <Panel>
      <StoreForm handleSubmit={handleSubmit} handleCancel={() => navigate('/')}/>
    </Panel>
  )
}

export default CreateStore;