import React, {useContext, useEffect, useState} from "react";
import FarmerForm from "./components/FarmerForm";
import {AuthContext} from "../../providers/AuthProvider";
import {useNavigate, useParams} from "react-router-dom";
import {Farmer} from "../../plugins/middleware-api-client";
import {defaultService} from "../../plugins/axios";
import Panel from "../../components/Panel/Panel";

const UpdateFarmer:React.FC = () => {
  let { farmerId } = useParams();
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const [farmer, setFarmer] = useState<Farmer | null>(null)

  useEffect(() => {
    if(farmerId) {
      fetchFarmer().then()
    }
  }, [farmerId])

  const fetchFarmer = async () => {
    const response = await defaultService.getFarmer(farmerId!, {
      headers: {
        'Authorization': `Bearer ${authContext.token}`
      }
    })
    setFarmer(response.data)
  }

  const handleSubmit = (farmer:Farmer) => {
      authContext.updateFarmer(farmerId as string, farmer).then(res => {
        navigate("/farmers")
      }).catch(err => {
        console.error(err)
      })
  }
  return (
    <Panel>
      { (farmer && authContext.activeStore) &&
        <FarmerForm farmerToUpdate={farmer} handleSubmit={handleSubmit} handleCancel={() => navigate("/farmers")}/>
      }
    </Panel>
  )
}

export default UpdateFarmer