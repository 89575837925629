import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import Button, {ButtonStyle} from "../../components/Button/Button";
import Heading from "../../components/Typography/Heading";
import Text from "../../components/Typography/Text";
import {TableData, TableHeader} from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import { useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import WarningModal from "../../components/Modal/WarningModal";


const FarmerList: React.FC = () => {
  const authProvider = useContext(AuthContext)
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
  const [farmerToDelete, setFarmerToDelete] = useState<string>("")
  const navigate = useNavigate()

  useEffect(() => {
    authProvider.fetchFarmers()
  }, [])

  useEffect(() => {
    if (farmerToDelete.length > 0) {
      setShowAlertModal(true)
    }
  }, [farmerToDelete])

  useEffect(() => {
    if (!showAlertModal) {
      setFarmerToDelete("")
    }
  }, [showAlertModal])

  const editFarmer = (farmerId:string) => {
    navigate(`/farmers/${farmerId}`)
  }

  const deleteFarmer = async () => {
    await authProvider.deleteFarmer(farmerToDelete)
    setShowAlertModal(false)
  }

  return (
    <Panel>
      <div className="sm:flex sm:items-center sticky top-0 bg-white pb-4 z-10">
        <div className="sm:flex-grow">
          <Heading>Boeren</Heading>
          <Text>Alle Boeren</Text>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 space-x-2">
          <Button
            onClick={() => {
              console.log("Adding a farmer!")
              navigate("/farmers/create") 
            }}
            buttonStyle={ButtonStyle.NEW}
          >
            Boer toevoegen
          </Button>
        </div>
      </div>

      <div className="mt-8 flex flex-col overflow-hidden">
        <div className="py-2">
          <div className="overflow-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-scroll">
            <table className="w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
              <tr>
                <TableHeader>Boer</TableHeader>
                <TableHeader>Plaats</TableHeader>
                <TableHeader><span className="sr-only">Aanpassen</span></TableHeader>
                <TableHeader><span className="sr-only">Verwijderen</span></TableHeader>
              </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
              {authProvider.farmers.map((farmer) => (
                <tr key={farmer.id} onDoubleClick={() => editFarmer(farmer.id)} className="hover:backdrop-brightness-95">
                  <TableData>
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={farmer.image} alt=""/>
                      </div>
                      <div className="ml-4">
                        <div className="font-medium">{farmer.name}</div>
                        <div className="text-secondary/80">
                          {farmer.description.substring(0, 25)}{farmer.description.length > 25 && "..."}
                        </div>
                      </div>
                    </div>
                  </TableData>
                  <TableData>{farmer.location_name}</TableData>
                  <TableData classList="text-right text-red-500 hover:text-red-800 cursor-pointer">
                    <TrashIcon
                      onClick={() => setFarmerToDelete(farmer.id)} className="h-6 w-6"
                    />
                    <span className="sr-only">, {farmer.name}</span>
                  </TableData>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <WarningModal
        isOpen={showAlertModal}
        setIsOpen={setShowAlertModal}
        confirmAction={deleteFarmer}
        cancelAction={setShowAlertModal}
        displayText="Weet je zeker dat je deze boer wilt verwijderen?"
      />
    </Panel>
  )
}

export default FarmerList;