import React, {useContext, useEffect, useState} from "react";
import {AuthContext, AuthUser} from "../../providers/AuthProvider";
import {useNavigate} from "react-router-dom";
import Button, {ButtonStyle} from "../../components/Button/Button";
import A from "../../components/Typography/A";
import Heading from "../../components/Typography/Heading";
import Input from "../../components/Form/Input";

const Login: React.FC = () => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState<AuthUser>({email:"", password:""})

  const handleLogin = (e: React.SyntheticEvent) => {
    e.preventDefault();
    authContext.login(authUser);
  }

  useEffect(() => {
    if(authContext.loaded && authContext.user) {
      navigate('/')
    }
  }, [authContext.loaded, authContext.user])
  return (
    <>
      <div className="min-h-screen flex bg-default">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-12 w-auto"
                src="/assets/images/primary_logo.png"
                alt="Workflow"
              />
              <Heading classList="mt-6">Log in voor je winkel(s)</Heading>
            </div>

            <div className="mt-8">

              <div className="mt-6">
                <form onSubmit={handleLogin} method="POST" className="space-y-6">
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    inline={false}
                    onChange={(e) => {
                      setAuthUser(f => ({...f, email: e.target.value}))
                    }}
                    label="E-mailadres"
                  />

                  <Input
                    id="password"
                    name="password"
                    type="password"
                    inline={false}
                    autoComplete="current-password"
                    onChange={(e) => {
                      setAuthUser(f => ({...f, password: e.target.value}))
                    }}
                    label={"Wachtwoord"}
                  />

                  <div className="flex items-center justify-end">
                    <div className="text-sm">
                      <A href="#">
                        Wachtwoord vergeten?
                      </A>
                    </div>
                  </div>

                  <div>
                    <Button
                      classList="w-full"
                      buttonStyle={ButtonStyle.SAVE}
                    >
                      Inloggen
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  )
}

export default Login;