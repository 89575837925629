import React, {useEffect, useState} from "react";
import Input from "../../../components/Form/Input";

interface FarmerUspsFormProps {
  uspIndex: number;
  farmerUsp: string;
  updateUsp: (value: string) => void;
  removeUsp: (uspIndex: number) => void;
}

const FarmerUspsForm:React.FC<FarmerUspsFormProps> = ({uspIndex, farmerUsp, updateUsp, removeUsp}) => {
  const [usp, setUsp] = useState<string>(farmerUsp)
  useEffect(() => {
    setUsp(farmerUsp)
  }, [farmerUsp])
  return (
    <div
      key={`farmer-usp-${uspIndex}`}
      className="flex"
    >
      <Input
        inline={false}
        id={`farmer-usp-${uspIndex}`}
        name={`farmer-usp-${uspIndex}`}
        classList={"flex-grow"}
        type="text"
        defaultValue={usp}
        onChange={(e) => {
          updateUsp(e.target.value)
        }}
      />
      <button
        className="text-primary hover:primary-600 w-8"
        type="button"
        onClick={() => removeUsp(uspIndex)}
      >
        x
      </button>
    </div>
  )
}

export default FarmerUspsForm;