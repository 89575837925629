import React, {useContext, useEffect, useState} from "react";
import ProductForm from "./components/ProductForm";
import {AuthContext} from "../../providers/AuthProvider";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Product, StoreProduct} from "../../plugins/middleware-api-client";
import {defaultService} from "../../plugins/axios";
import Panel from "../../components/Panel/Panel";

const UpdateProduct:React.FC = () => {
  let { productId } = useParams();
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const [product, setProduct] = useState<Product | null>(null)

  const {search} = useLocation();
  useEffect(() => {
    if(productId) {
      fetchProduct().then()
    }
  }, [productId])
  const fetchProduct = async () => {
    const response = await defaultService.getProduct(productId!, {
      headers: {
        'Authorization': `Bearer ${authContext.token}`
      }
    })
    setProduct(response.data)
  }

  const handleSubmit = (product: Product, stock: StoreProduct[]) => {
    if(authContext.activeStoreId !== "" && productId) {

      // product.storeId = authContext.activeStoreId
      authContext.updateProduct(productId, product).then(res => {
        navigate("/products"+search)
      }).catch(err => {
        console.error(err)
      })

      authContext.updateStoreProducts(
        productId,
        stock,
      ).catch(err => {
        console.error(err)
      })
    }
  }
  return (
    <div className="mb-28">
      <Panel>
        { (product && authContext.activeStore) &&
          <ProductForm productToUpdate={product} handleSubmit={handleSubmit} handleCancel={() => navigate("/products"+search)}/>
        }
      </Panel>
    </div>
  )
}

export default UpdateProduct
