import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import FarmerForm from "./components/FarmerForm";
import {Farmer} from "../../plugins/middleware-api-client";
import {AuthContext} from "../../providers/AuthProvider";
import Panel from "../../components/Panel/Panel";

const CreateFarmer:React.FC = () => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const handleSubmit = (farmer:Farmer) => {
    authContext.createFarmer(farmer).then(res => {
        navigate("/farmers")
    }).catch(err => {
        console.error(err)
    })
  }

  return (
    <Panel>
      {authContext.activeStore &&
        <FarmerForm handleSubmit={handleSubmit} handleCancel={() => navigate("/farmers")}/>
      }
    </Panel>
  )
}

export default CreateFarmer;