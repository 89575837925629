import {
  CheckIcon,
  CurrencyEuroIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import {useEffect, useState} from "react";
import { TableData } from "../../../components/Table/Table";
import {Product, StoreProduct} from "../../../plugins/middleware-api-client";
import { classNames } from "../../../utils/classNames";
import {calculateProfitMargin, calculatePriceIncl, calculateCostPrice} from "../../../utils/product";

interface ProductTableRowProps {
  product: Product;
  storeProduct: StoreProduct;
  farmerName: string;
  className: string;
  onDoubleClick: () => void;
  deleteCallback: (_: string) => void;
  confirmCallback: (product: Product) => void;
}

const ProductTableRow: React.FC<ProductTableRowProps> = (props) => {
  const [editable, setEditable] = useState(false);
  const [product, setProduct] = useState(props.product);

  const canEditCostPrice = props.product.calculationMethod === "priceIncl";
  const canEditPriceIncl = props.product.calculationMethod === "costPrice";
  const canEditMargin = props.product.calculationMethod === "profitMargin";

  useEffect(() => {
    if (canEditCostPrice) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        priceIncl: calculatePriceIncl(prevProduct),
      }));
    } else if (canEditPriceIncl) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        costPrice: calculateCostPrice(prevProduct),
      }));
    } else if (canEditMargin) {
      setProduct((prevProduct) => {
        return {
          ...prevProduct,
          margin: calculateProfitMargin(prevProduct)
        }
      });
    }
  }, [canEditPriceIncl, canEditCostPrice, canEditMargin, product.costPrice, product.priceIncl, product.margin, product.vat]);

  return (
    <tr
      key={props.product.id}
      onDoubleClick={editable ? () => {} : props.onDoubleClick}
      className={props.className}
    >
      <TableData>
        <div className="flex items-center">
          <div className="h-10 w-10 flex-shrink-0">
            <img
              className="h-10 w-10 rounded-full"
              src={product.image}
              alt=""
            />
          </div>
          <div className="ml-4">
            <div className="font-medium">{product.title.substring(0, 20)}
              {product.title.length > 20 && "..."}
            </div>
            <div className="text-secondary/80">{props.farmerName}</div>
            <div className="text-secondary/80 text-xs">
              {product.description.substring(0, 25)}
              {product.description.length > 25 && "..."}
            </div>
          </div>
        </div>
      </TableData>
      <TableData>{product.category}</TableData>
      <TableData>
        {props.storeProduct.stockLevel}
      </TableData>
      <TableData>
        {editable && (canEditCostPrice || canEditMargin) ? (
          <div className="mt-1 relative rounded-md">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <CurrencyEuroIcon className="w-5 mr-1 inline-block text-gray-500" />
            </div>
            <input
              type="number"
              name="price"
              id="price"
              step="any"
              className={`w-28 focus:ring-secondary focus:border-secondary block pl-8 sm:text-sm border-gray-300 rounded-md"`}
              defaultValue={product.costPrice / 100}
              disabled={!canEditCostPrice && !canEditMargin}
              aria-describedby="price-currency"
              onChange={(e) => {
                setProduct((prevProduct) => ({
                  ...prevProduct,
                  costPrice: Math.round(parseFloat(e.target.value) * 100),
                }));
              }}
            />
          </div>
        ) : (
          <span className="flex items-center">
            <CurrencyEuroIcon className="w-5 mr-1 inline-block" />
            {product.costPrice / 100}
          </span>
        )}
      </TableData>
      <TableData>
        {editable && (canEditPriceIncl || canEditMargin) ? (
          <div className="mt-1 relative rounded-md">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <CurrencyEuroIcon className="w-5 mr-1 inline-block text-gray-500" />
            </div>
            <input
              type="number"
              name="price"
              id="price"
              step="any"
              className="w-28 focus:ring-secondary focus:border-secondary block pl-8 sm:text-sm border-gray-300 rounded-md"
              disabled={!canEditPriceIncl && !canEditMargin}
              defaultValue={product.priceIncl / 100}
              aria-describedby="price-currency"
              onChange={(e) => {
                setProduct((prevProduct) => ({
                  ...prevProduct,
                  priceIncl: Math.round(parseFloat(e.target.value) * 100),
                }));
              }}
            />
          </div>
        ) : (
          <span className="flex items-center">
            <CurrencyEuroIcon className="w-5 mr-1 inline-block" />
            {product.priceIncl / 100}
          </span>
        )}
        {editable ? (
          <></>
        ) : (
          <div className="text-secondary/80">
            <span className="flex items-center">
              <CurrencyEuroIcon className="w-5 mr-1 inline-block opacity-0" />
              {product.vat} %
            </span>
          </div>
        )}
      </TableData>
      <TableData>
        {editable && !canEditMargin ? (
          <div className="mt-1 relative rounded-md">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="w-5 mr-1 inline-block text-gray-500">%</span>
            </div>
            <input
              type="number"
              name="margin"
              id="margin"
              step="any"
              className="w-28 focus:ring-secondary focus:border-secondary pl-8 block sm:text-sm border-gray-300 rounded-md"
              disabled={canEditMargin}
              defaultValue={product.margin}
              onChange={(e) => {
                setProduct((prevProduct) => ({
                  ...prevProduct,
                  margin: Math.round(parseFloat(e.target.value)),
                }));
              }}
            />
          </div>
        ) : (
          <span>{!canEditMargin ? product.margin : calculateProfitMargin(product)} %</span>
        )}{" "}
      </TableData>
      <TableData>
        <span
          onClick={editable ? () => setProduct(prevProduct => ({
            ...prevProduct,
            isVisible: !prevProduct.isVisible
          })) : () => null}
          className={classNames(
            "inline-flex rounded-full px-2 text-xs font-semibold leading-5 text-secondary",
            product.isVisible ? "bg-green-100" : "bg-red-100",
            editable && "cursor-pointer"
          )}
        >
          {product.isVisible ? "Actief" : "Inactief"}
        </span>
      </TableData>
      <TableData classList="text-right text-red-500 hover:text-red-800 cursor-pointer">
        <TrashIcon
          onClick={() => props.deleteCallback(product.id)}
          className="h-6 w-6"
        />
        <span className="sr-only">, {product.title}</span>
      </TableData>
      <TableData classList="text-right text-red-500 hover:text-red-800 cursor-pointer">
        {editable ? (
          <CheckIcon
            onClick={() => {
              props.confirmCallback(product);
              setEditable(false);
            }}
            className="h-6 w-6"
          ></CheckIcon>
        ) : (
          <PencilIcon
            onClick={() => setEditable(true)}
            className="h-6 w-6"
          ></PencilIcon>
        )}
      </TableData>
    </tr>
  );
};

export default ProductTableRow;
